import { Typography, Box, Modal, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import ImageDropZone from '../../../Buttons/ImageDropZone';
import { uploadGoogleAssetImage } from '../../../../services/googleAds';
import { FloatingButton } from '../../../Buttons';
import { MdDeleteOutline } from 'react-icons/md';

const getAspectRatio = (w: number, h: number) => (w > h ? w / h : h / w);
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: `4px solid ${DEFAULT_COLOR_THEME}`,
  boxShadow: 24,
  p: 4,
};

const lightboxModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'transparent',
};

const validatorFunc = (file: File) => {
  //check resolution width and height
  const minW = 300;
  const minH = 300;
  const maxW = 1200;
  const maxH = 1200;
  const maxSize = 5000000;

  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    if (width < minW || height < minH) {
      return {
        code: 'file-too-small',
        message: 'File is too small',
        size: { width, height },
        ratio: getAspectRatio(width, height),
      };
    }

    if (width > maxW || height > maxH) {
      return {
        code: 'file-too-large',
        message: 'File is too large',
        size: { width, height },
        ratio: getAspectRatio(width, height),
      };
    }
  };
  img.onerror = () => {
    return {
      code: 'file-error',
      message: 'File error',
    };
  };

  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: 'File is too large',
    };
  }

  return null;
};

interface AssetGoogleImagesProps {
  brandId: string;
  databaseImages: {
    url: string;
    height: number;
    width: number;
    asset_id: string;
    public_id: string;
  }[];
  doneUploading: any;
  deleteImage: any;
  setIsDirty: any;
  showCollection?: boolean;
}

const AssetGoogleImages: React.FC<AssetGoogleImagesProps> = ({
  brandId,
  databaseImages,
  doneUploading,
  deleteImage,
  setIsDirty,
  showCollection = true,
}) => {
  const [images, setImages] = useState([]);
  const [rejectedImages, setRejectedImages] = useState([]);
  const [uploadingModal, setUploadingModal] = useState(false);
  const [lightboxModal, setLightboxModal] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');
  const [uploadMode, setUploadMode] = useState(false);
  const endofpage = React.useRef<HTMLDivElement>(null);

  const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const prepImageUpload = async () => {
    setUploadingModal(true);

    if (images.length === 0) {
      setUploadingModal(false);
      return;
    }

    const uploadedImages = await Promise.all(
      images.map(async (image: any) => {
        const res = await uploadGoogleAssetImage({
          brandId: brandId,
          resourceName: image.name + `_${Date.now()}`,
          image: image.src,
        });

        return res;
      }),
    );

    if (showCollection) {
      setUploadMode(false);
    }
    doneUploading();
    setImages([]);
    setRejectedImages([]);
    setUploadingModal(false);
  };

  const addRejects = (ri: any) => {
    setRejectedImages([
      ...rejectedImages,
      ...ri.map((image: any) => {
        return {
          file: image.file,
          error: image.errors[0].message,
        };
      }),
    ]);
  };

  const imageDelete = (url: string) => {
    //find image in databaseImages
    const imageToDelete = databaseImages.find((image) => image.url === url);
    setLightboxModal(false);
    deleteImage(imageToDelete.public_id);
  };

  const addImages = async (newImages: any) => {
    //convert to base64
    const base64Images = await Promise.all(
      newImages.map(async (image: any) => {
        const base64 = await blobToBase64(image).then((res) => {
          //get image height and width
          let height = 0;
          let width = 0;
          const img = new Image();
          img.src = res as string;

          return new Promise((resolve) => {
            img.onload = () => {
              height = img.height;
              width = img.width;
              resolve({
                name: image.name,
                size: image.size,
                src: res,
                dimensions: {
                  height,
                  width,
                },
                aspectRatio: getAspectRatio(width, height),
              });
            };
          });
        });

        return base64;
      }),
    );

    //setImages([...images, ...newImages]);
    setImages([...images, ...base64Images]);
    //scroll to endofpage
    endofpage.current?.scrollIntoView({ behavior: 'smooth' });
    setIsDirty(true);
  };

  const lightBoxOpen = (url: string) => {
    setLightboxImage(url);
    setLightboxModal(true);
  };

  useEffect(() => {
    if (!showCollection) {
      setUploadMode(true);
    }
  }, []);

  return (
    <Box>
      <Modal
        open={uploadingModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Uploading Images, please wait...
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
              justifyContent: 'center',
              maxWidth: 600,
              marginX: 'auto',
            }}
          >
            {images.map((image, index) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'start',
                  padding: '0.5rem',
                  border: '1px solid #096f4d',
                  borderRadius: 8,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: 150,
                    height: 150,
                    borderRadius: 8,
                    overflow: 'hidden',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    flexShrink: 0,
                  }}
                  key={index}
                >
                  <img
                    src={image.src}
                    alt={`${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      background: 'rgba(0,0,0,0.5)',
                      color: 'white',
                      padding: '0.5rem',
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>
                <Box>
                  <Box>{image.name}</Box>
                  <Box>
                    {image.dimensions.width}x{image.dimensions.height}px{' '}
                    {image.aspectRatio}:1
                  </Box>
                  <Box>{Math.round(image.size / 1000)}KB</Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={lightboxModal}
        onClose={() => {
          setLightboxModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={lightboxModalStyle}>
          <img
            src={lightboxImage}
            alt="lightbox"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onClick={() => {
              setLightboxModal(false);
            }}
          />
          <Box sx={{ marginTop: 0 }}>
            <Button
              variant="contained"
              sx={{
                width: `100%`,
                backgroundColor: '#993333',
                '&:hover': {
                  backgroundColor: '#ff0000',
                },
              }}
              onClick={() => {
                imageDelete(lightboxImage);
              }}
            >
              <MdDeleteOutline size={36} />
            </Button>
          </Box>
        </Box>
      </Modal>
      {showCollection ? (
        <FloatingButton title="Save" onClick={prepImageUpload} />
      ) : null}

      <Box
        display={showCollection ? 'flex' : `none`}
        justifyContent={`space-between`}
        gap={4}
        sx={{
          width: `100%`,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Images [{databaseImages.length}]
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ paddingY: 0, paddingX: 2, height: 30 }}
          onClick={() => {
            setUploadMode(!uploadMode);
          }}
        >
          {uploadMode ? 'Cancel' : 'Upload Images'}
        </Button>
      </Box>
      {!uploadMode ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
            justifyContent: 'center',
            marginX: 'auto',
          }}
        >
          {databaseImages.length > 0 ? (
            <>
              {databaseImages.map((image, index) => (
                <Button
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: '0.5rem',
                    border: '1px solid #096f4d',
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    lightBoxOpen(image.url);
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      width: 150,
                      height: 150,
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                      flexShrink: 0,
                    }}
                    key={index}
                  >
                    <img
                      src={image.url}
                      alt={`${index + 1}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        color: 'white',
                        padding: '0.5rem',
                      }}
                    >
                      {index + 1}
                    </Box>
                  </Box>
                  <Box>{getAspectRatio(image.width, image.height)}:1</Box>
                  <Box>
                    {image.width} x {image.height}
                  </Box>
                </Button>
              ))}
            </>
          ) : (
            <Box textAlign={`center`} mt={4}>
              <Typography
                variant="body1"
                fontWeight={800}
                mb={2}
                color={DEFAULT_COLOR_THEME}
              >
                No Images found
              </Typography>
            </Box>
          )}
        </Box>
      ) : null}

      {uploadMode ? (
        <>
          <Box
            sx={{
              maxWidth: 500,
              marginX: 'auto',
              marginBottom: '1rem',
            }}
          >
            <ImageDropZone
              setImages={addImages}
              sendRejects={addRejects}
              validatorFunc={validatorFunc}
            />
          </Box>

          {images.length > 0 ? (
            <>
              <Typography
                variant="body1"
                fontWeight={800}
                mb={2}
                color={DEFAULT_COLOR_THEME}
              >
                Images to be uploaded:
              </Typography>
              {!showCollection ? (
                <Box display={`flex`} justifyContent={`end`} paddingX={4}>
                  <Button
                    onClick={prepImageUpload}
                    variant="contained"
                    color="primary"
                    sx={{ paddingY: 0, paddingX: 2, height: 30 }}
                  >
                    Upload {images.length} Image{images.length > 1 ? 's' : ''}
                  </Button>
                </Box>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '1rem',
                  justifyContent: 'center',
                  maxWidth: 600,
                  marginX: 'auto',
                }}
              >
                {images.map((image, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0.5rem',
                      border: '1px solid #096f4d',
                      borderRadius: 2,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: 150,
                        height: 150,
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        flexShrink: 0,
                      }}
                      key={index}
                    >
                      <img
                        src={image.src}
                        alt={`${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          background: 'rgba(0,0,0,0.5)',
                          color: 'white',
                          padding: '0.5rem',
                        }}
                      >
                        {index + 1}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Box>{image.name}</Box>
                      <Box>
                        {image.dimensions.width}x{image.dimensions.height}px{' '}
                        {image.aspectRatio}:1
                      </Box>
                      <Box>{Math.round(image.size / 1000)}KB</Box>
                    </Box>
                    <Button
                      sx={{
                        flexShrink: 0,
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: '#ffcccc',
                        },
                        padding: 1,
                        minWidth: 0,
                        borderRadius: `100%`,
                      }}
                      onClick={() => {
                        const temp = [...images];
                        temp.splice(index, 1);
                        setImages(temp);
                        setIsDirty(true);
                      }}
                    >
                      <MdDeleteOutline size={32} color="#ababab" />
                    </Button>
                  </Box>
                ))}
              </Box>
            </>
          ) : null}

          {rejectedImages.length > 0 ? (
            <>
              <Typography
                variant="body1"
                fontWeight={800}
                mb={2}
                color={DEFAULT_COLOR_THEME}
              >
                Rejected Images:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '1rem',
                  justifyContent: 'center',
                  maxWidth: 600,
                  marginX: 'auto',
                }}
              >
                {rejectedImages.map((image, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'start',
                      padding: '0.5rem',
                      border: '1px solid red',
                      borderRadius: 8,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: 150,
                        height: 150,
                        borderRadius: 8,
                        overflow: 'hidden',
                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                      }}
                      key={index}
                    >
                      <img
                        src={URL.createObjectURL(image.file)}
                        alt={`${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          background: 'rgba(0,0,0,0.5)',
                          color: 'white',
                          padding: '0.5rem',
                        }}
                      >
                        {index + 1}
                      </Box>
                    </Box>
                    <Box>
                      <Box>{image.file.name}</Box>
                      <Box>{Math.round(image.file.size / 1000)}KB</Box>
                      <Box>{image.error?.size ? image.error.size : ''}</Box>
                      <Box>{image.error}</Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : null}
        </>
      ) : null}
      <Box
        ref={endofpage}
        sx={{
          color: `transparent`,
        }}
      >
        .
      </Box>
    </Box>
  );
};

export default AssetGoogleImages;
