import React, { useState } from 'react';
import { Brand } from '../../../types';
import { Box, Modal, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { DEFAULT_COLOR_THEME } from '../../../utils';
import GoogleAssetsMainMenu from '../../Navigation/GoogleAssetsMainMenu';
import GoogleAssetsSubMenu from '../../Navigation/GoogleAssetsSubMenu';
import GoogleAssetzForm from './GoogleAssetzForm';

const GoogleAssetzIndex: React.FC = () => {
  const campaignProvider = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const [connectedAccountIds, setConnectedAccountIds] = useState<string[]>([]);
  const [googleAssetzSubMenu, setGoogleAssetzSubMenu] =
    useState<string>('account');
  const [googleAssetzMainMenu, setGoogleAssetzMainMenu] =
    useState<string>('Home');

  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);

  const [isGoogleDirty, setIsGoogleDirty] = useState<boolean>(false);
  const [dirtyModal, setDirtyModal] = useState<{
    active: boolean;
    callback: any;
  }>({
    active: false,
    callback: null,
  });

  const checkDirty = (cb: any) => {
    if (isGoogleDirty) {
      setDirtyModal({
        active: true,
        callback: cb,
      });
    } else {
      cb();
    }
  };

  return (
    <>
      <Modal
        open={dirtyModal.active}
        onClose={() => {}}
        aria-labelledby="dirty-modal-title"
        aria-describedby="dirty-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: `2px solid ${DEFAULT_COLOR_THEME}`,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography>
            You are about to leave the page with{' '}
            <Typography
              sx={{
                fontWeight: 800,
                display: 'inline',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              unsaved changes
            </Typography>
            .
          </Typography>
          <Typography my={2}>
            Do you want to proceed{` `}
            <Typography
              sx={{
                fontWeight: 800,
                display: 'inline',
                color: `#ee3333`,
              }}
            >
              without saving
            </Typography>{' '}
            your changes?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                minWidth: '110px',
                backgroundColor: '#335533',
                '&:hover': {
                  backgroundColor: '#558855',
                },
              }}
              onClick={() => {
                dirtyModal.callback();
                setDirtyModal({
                  active: false,
                  callback: null,
                });
                setIsGoogleDirty(false);
              }}
            >
              Proceed
            </Button>
            <Button
              variant="contained"
              sx={{
                minWidth: '110px',
                backgroundColor: '#ff7777',
                '&:hover': {
                  backgroundColor: '#ffaaaa',
                },
              }}
              onClick={() => {
                setDirtyModal({
                  active: false,
                  callback: null,
                });
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        display={`block`}
        justifyContent={`left`}
        alignItems={`start`}
        width={`100%`}
        bgcolor={`#ffffff`}
        marginTop={-6}
      >
        <GoogleAssetsMainMenu
          checkDirty={checkDirty}
          setGoogleAssetzMainMenu={setGoogleAssetzMainMenu}
          selectedMainmenu={googleAssetzMainMenu}
        />
        <GoogleAssetsSubMenu
          checkDirty={checkDirty}
          setGoogleAssetzSubMenu={setGoogleAssetzSubMenu}
          selectedSubmenu={googleAssetzSubMenu}
          selectedMainmenu={googleAssetzMainMenu}
        />
        <GoogleAssetzForm
          checkDirty={checkDirty}
          campaignProvider={campaignProvider}
          defaultRefreshToken={defaultRefreshToken}
          brand={location || brand}
          connectedAccountsIds={connectedAccountIds}
          selectedMainmenu={googleAssetzMainMenu}
          selectedSubmenu={googleAssetzSubMenu}
          setIsDirty={setIsGoogleDirty}
        />
      </Box>
    </>
  );
};

export default GoogleAssetzIndex;
